import revive_payload_client_4sVQNw7RlN from "/var/www/app.winx.ai/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/var/www/app.winx.ai/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import auth_client_CtFjRSnW1L from "/var/www/app.winx.ai/node_modules/winx-auth/dist/runtime/plugins/auth.client.mjs";
import plugin_vue3_A0OWXRrUgq from "/var/www/app.winx.ai/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/app.winx.ai/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/var/www/app.winx.ai/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/var/www/app.winx.ai/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_IFbScM8fhe from "/var/www/app.winx.ai/node_modules/nuxt-quasar-ui/dist/runtime/plugin.mjs";
import plugin_w2VlvAEcpa from "/var/www/app.winx.ai/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import auth_redirect_h8DTFObWwU from "/var/www/app.winx.ai/node_modules/winx-auth/dist/runtime/plugins/auth-redirect.mjs";
import i18n_yfWm7jX06p from "/var/www/app.winx.ai/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/app.winx.ai/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import highcharts_client_MYKkaxDq22 from "/var/www/app.winx.ai/plugins/highcharts.client.ts";
import bootstrap_client_ykIwYROv5l from "/var/www/app.winx.ai/node_modules/winx-base/plugins/bootstrap.client.ts";
import bugsnag_kMMx9tnXGD from "/var/www/app.winx.ai/node_modules/winx-base/plugins/bugsnag.ts";
import core_mKO01y0X7r from "/var/www/app.winx.ai/node_modules/winx-base/plugins/core.ts";
import fontawesome_aTLXsWXDWt from "/var/www/app.winx.ai/node_modules/winx-base/plugins/fontawesome.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  auth_client_CtFjRSnW1L,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  plugin_IFbScM8fhe,
  plugin_w2VlvAEcpa,
  auth_redirect_h8DTFObWwU,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  highcharts_client_MYKkaxDq22,
  bootstrap_client_ykIwYROv5l,
  bugsnag_kMMx9tnXGD,
  core_mKO01y0X7r,
  fontawesome_aTLXsWXDWt
]