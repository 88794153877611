interface IPatterns {
  primaryColor: string;
  secondaryColor: string;
  successColor: string;
  warningColor: string;
  dangerColor: string;
  infoColor: string;
  lightColor: string;
  darkColor: string;
}

const patterns = {
  primaryColor: '#55DCD3',
  secondaryColor: '#011F44',
  successColor: '#55DCD3',
  warningColor: '#FFEB8A',
  dangerColor: '#C46058',
  infoColor: '#94C0D4',
  lightColor: '#E6E6E6',
  darkColor: '',
};

export default defineAppConfig({
  theme: {
    patterns,
  },
});

declare module 'nuxt/schema' {
  interface AppConfigInput {
    /** Patterns configuration */
    theme?: {
      patterns: IPatterns;
    };
  }
}
